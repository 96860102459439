import React from "react"
import { Helmet } from "react-helmet"
import { Grid, Hidden } from "@material-ui/core"
import { ContactForm } from "../components/ContactForm"

import Head from "../components/head"
import Header from "../components/Header_normal"
import Footer from "../components/footer"
import Box from "@material-ui/core/Box"

import WrapTypo from "../components/WrapTypo"

const Contacts = () => {
  return (
    <>
      <Head />
      <Box bgcolor="#fff">
        <Header isBlack={true} />
        <Box>
          <Box margin="120px auto 20px" width="80%" position="relative">
            <Hidden xsDown>
              <Box position="absolute" top="0" right="0">
                <Box
                  style={{
                    WebkitWritingMode: "vertical-rl",
                    msWritingMode: "tb-rl",
                    writingMode: "vertical-rl"
                  }}>
                  <WrapTypo>お問い合わせ</WrapTypo>
                </Box>
              </Box>
            </Hidden>
            <Hidden smUp>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <Box
                    style={{
                      WebkitWritingMode: "vertical-rl",
                      msWritingMode: "tb-rl",
                      writingMode: "vertical-rl"
                    }}>
                    <WrapTypo>お問い合わせ</WrapTypo>
                  </Box>
                </Grid>
              </Grid>
            </Hidden>
            <Box maxWidth="1080px" margin="auto">
              <WrapTypo>
                <br /><br />株式会社早藤工務店<br />
                〒998-0016　山形県酒田市北千日町4-2<br />
                TEL : 0234-33-7460<br />
                FAX : 0234-33-7460<br />
                E-MAIL :<br /><br />
                ◎コロナ対策徹底中<br />
                入り口前の消毒、事務所内の換気などを徹底しております。<br /><br />
                必要項目にご入力後「入力内容の確認」に進んでください<br />
                すべて<span style={{ color: "red" }}>入力必須項目</span>です
              </WrapTypo>
            </Box>
          </Box>
          <Grid item xs={12}>
            <ContactForm />
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  )
}

export default Contacts
